import React from 'react';
import { Button, Typography, ThemeProvider } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#000', // Yellow color
    },
  },
});

const UploadButton = styled(Button)({
  width: '200px', // Set a fixed width for the button
  color: '#000',
  '&:hover': {
    backgroundColor: customTheme.palette.primary.light,
  },
  display: 'flex', // Display the button as a flex container
  justifyContent: 'flex-start', // Align content to the left
  alignItems: 'center', // Vertically center content
});

const FileUploadContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '8px',
  width: '100%',
});

const FilePreview = styled('img')({
  width: '160px', // Adjust the width as needed
  borderRadius: '8px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  marginTop: '8px',
});

const FileUpload = ({ label, onChange, accept, hint, id, selectedFile }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <FileUploadContainer>
        <label htmlFor={id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <input
            id={id}
            type="file"
            style={{ display: 'none' }}
            onChange={onChange}
            accept={accept} // Accepts the list of allowed file types, e.g., "image/*, .pdf, .doc"
          />
          <UploadButton variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
            {label}
          </UploadButton>
          <Typography variant="body2" color="textSecondary" style={{ marginTop: '4px' }}>
            {hint}
          </Typography>
        </label>
        {selectedFile && (
          <FilePreview src={URL.createObjectURL(selectedFile)} alt="Selected" />
        )}
      </FileUploadContainer>
    </ThemeProvider>
  );
};

export default FileUpload;
