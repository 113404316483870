import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Paper, CssBaseline, ThemeProvider, styled, Button, ButtonGroup, useMediaQuery, useTheme, Box, TextField, Avatar, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FileUpload from '../components/FileUpload';
import AppleIcon from '@mui/icons-material/Apple';
import GooglePlayIcon from '@mui/icons-material/PlayCircleOutline';
import HuaweiIcon from '../assets/images/HuaweiIcon.jsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import axios from 'axios';
// ... your image imports ...

const theme = createTheme({
  palette: {
    primary: {
      main: '#f3a83b', // Yellow color
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", sans-serif',
  },
});

// ... your styled components ...
const StyledSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  marginBottom: theme.spacing(2),
}));

const ImageColumn = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2), // Add margin to separate rows on mobile
});

const TextColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column', // Display text in a column layout
  // alignItems: 'center',
  // justifyContent: 'center',
});

const CustomButton = ({ startIcon, label, link }) => (
  <ThemeProvider theme={theme}>
    <a href={link} target="_blank" rel="noopener noreferrer" className="custom-button-link" style={{ width: '100%', margin: '5px' }}>
      <Button
        variant="contained"
        sx={{
          width: '100%',
          color: 'white',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          paddingLeft: '1.5em',
          mb: { xs: 1, sm: 0 },
          borderRadius: '10px',
          textTransform: 'none',
        }}
        startIcon={startIcon}
      >
        <span style={{ fontFamily: 'roboto' }}>{label}</span>
      </Button>
    </a>
  </ThemeProvider>
);

const RegistrationForm = () => {
  //Operator Identification
  const location = useLocation();
  const path = location.pathname;

  //Loading 
  const [loading, setLoading] = useState(false); // To track form submission status
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  // Personal information
  const [name, setName] = useState('');
  const [driverLicense, setDriverLicense] = useState(''); 
  const [phoneNumber, setPhoneNumber] = useState(''); // 10 digits

  //Vehicle Details
  const [carManufacturer, setCarManufacturer] = useState('');
  const [carModel, setCarModel] = useState(''); 
  const [carProductionYear, setCarProductionYear] = useState('');
  const [numberOfPassenegers, setNumberOfPassenegers] = useState('');
  const [carColor, setCarColor] = useState('');
  const [plateNumber, setPlateNumber] = useState('');
  const [plateLetters, setPlateLetters] = useState('');
  const [boardNumber, setBoardNumber] = useState('');

  //Spark Documents
  const [drivingLicenseFile, setDrivingLicenceFile] = useState(null);
  const [personalIdFile, setPersonalIdFile] = useState(null);
  const [vehichleLicenseFile, setVehichleLicenseFile] = useState(null);
  const [vehichleInsuranceFile, setVehichleInsuranceFile] = useState(null);
  const [vehiclePhoto, setVehiclePhoto] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  //Spark Fields
  const [operatorId, setOperatorId] = useState(''); // From URL

  //WASL Fields
  const [identityNumber, setIdentityNumber] = useState(''); // 10 digits
  const [dateOfBirth, setDateOfBirth] = useState(''); //DD-MM-YYYY Hijri
  const [vehicleSequenceNumber, setVehicleSequenceNumber] = useState('');
  const [plateLetterRight, setPlateLetterRight] = useState(''); //We conclude
  const [plateLetterMiddle, setPlateLetterMiddle] = useState(''); //We conclude
  const [plateLetterLeft, setPlateLetterLeft] = useState(''); //We conclude
  const [carType, setCarType] = useState(''); //Drop Down 1-Private 2-Taxi

  const [filesUploaded, setFilesUploaded] = useState(false);


  const cleanForm = () => {
    setName('');
    setDriverLicense('');
    setPhoneNumber('');
    setCarManufacturer('');
    setCarModel('');
    setCarProductionYear('');
    setNumberOfPassenegers('');
    setCarColor('');
    setPlateNumber('');
    setPlateLetters('');
    setBoardNumber('');
    setDrivingLicenceFile(null);
    setPersonalIdFile(null);
    setVehichleLicenseFile(null);
    setVehichleInsuranceFile(null);
    setOperatorId('');
    setIdentityNumber('');
    setDateOfBirth('');
    setVehicleSequenceNumber('');
    setPlateLetterRight('');
    setPlateLetterMiddle('');
    setPlateLetterLeft('');
    setCarType('');
    setVehiclePhoto(null);
    setProfilePhoto(null);
  };

  

  const handleDrivingLicenceUpload = (event) => {
    setDrivingLicenceFile(event.target.files[0]);
  };

  const handlePersonalIdUpload = (event) => {
    setPersonalIdFile(event.target.files[0]);
  };

  const handleVehichleLicenseUpload = (event) => {
    setVehichleLicenseFile(event.target.files[0]);
  };

  const handleVehichleInsuranceUpload = (event) => {
    setVehichleInsuranceFile(event.target.files[0]);
  };

  const handleVehiclePhotoUpload = (event) => {
    setVehiclePhoto(event.target.files[0]);
  };

  const handleProfilePhotoUpload = (event) => {
    setProfilePhoto(event.target.files[0]);
  };

  

  const handleSubmit = async (event) => {
    event.preventDefault();

   

    setLoading(true); // Start loading

    try {
      const formData = new FormData();
  
      // Append text data to the FormData object
      formData.append('name', name);
      formData.append('driverLicense', identityNumber.toString());
      formData.append('phoneNumber', phoneNumber.toString());
      formData.append('carManufacturer', carManufacturer);
      formData.append('carModel', carModel);
      formData.append('carProductionYear', carProductionYear.toString());
      formData.append('numberOfPassengers', numberOfPassenegers.toString());
      formData.append('carColor', carColor);
      formData.append('plateNumber', plateNumber.toString());
      formData.append('plateLetters', plateLetters);
      formData.append('boardNumber', boardNumber);
      formData.append('operatorId', operatorId);
      formData.append('identityNumber', identityNumber.toString());
      formData.append('dateOfBirth', dateOfBirth);
      formData.append('vehicleSequenceNumber', vehicleSequenceNumber.toString());
      formData.append('plateLetterRight', plateLetters.charAt(2));
      formData.append('plateLetterMiddle', plateLetters.charAt(1));
      formData.append('plateLetterLeft', plateLetters.charAt(0));
      formData.append('carType', carType.toString());
  
      // Append uploaded files to the FormData object
      formData.append('drivingLicenseFile', drivingLicenseFile);
      formData.append('personalIdFile', personalIdFile);
      formData.append('vehicleLicenseFile', vehichleLicenseFile);
      formData.append('vehicleInsuranceFile', vehichleInsuranceFile);
      formData.append('vehiclePhoto', vehiclePhoto);
      formData.append('profilePhoto', profilePhoto);

      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      };
      setUploading(true); // Start uploading
      // Make a POST request to the backend
      const response = await axios.post('https://formsapi.sparkride.sa/api/forms', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });
      setUploading(false); // Finish uploading
      setLoading(false); // Finish loading
      console.log('Form submitted successfully:', response.data);
      window.alert('Form submitted successfully!')
      cleanForm();
      setFilesUploaded(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error and show error message to user
      window.alert('Error submitting form!');
      setLoading(false); // Finish loading
      //cleanForm();
    }
  };

  useEffect(() => {
    if (loading) {
      document.body.style.cursor = 'wait';
    } else {
      document.body.style.cursor = 'default';
    }
  }, [loading]);


  useEffect(() => {
    // Set the operatorId based on the URL path
    if (path === '/c1' || path === '/C1') {
      setOperatorId('C1');
    } else if (path === '/c2' || path === '/C2') {
      setOperatorId('C2');
    } else if (path === '/c3' || path === '/C3') {
      setOperatorId('C3');
    } else if (path === '/') {
      setOperatorId('SparkRide');
    } else {
      setOperatorId('Other');
    }
    console.log(operatorId)
  }, [path]);


  return (
    <StyledSection>
      <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 3 }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextColumn>
            <Typography variant="h4" fontFamily="poppins" align="center" sx={{ mb: 5 }}>
              Registration Form
            </Typography>
            <ImageColumn> {/* Add a new container for the Avatar */}
            <label htmlFor="profilePictureInput" style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar
                alt="Profile"
                src={profilePhoto && URL.createObjectURL(profilePhoto)}
                sx={{ width: 75, height: 75, marginX: 'auto' }} 
              />
              <Typography variant="body2" color="textSecondary" style={{ marginTop: '4px' }}>
                Click to upload profile picture
              </Typography>
            </label>
            <input
              id="profilePictureInput"
              type="file"
              style={{ display: 'none' }}
              onChange={handleProfilePhotoUpload}
              accept="image/*"
            />
            </ImageColumn>
          </TextColumn>

          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': { m: 1 },
            }}
            autoComplete="off"
            onSubmit={handleSubmit}
          >
          <Grid container spacing={2} alignContent="center" justifyContent="center" sx={{ mt: 5 }}>
            
            {/* <Grid item xs={12} sm={12} md={12}>
              <Avatar
                  alt="Profile"
                  src="https://www.w3schools.com/howto/img_avatar.png"
                  sx={{ width: 75, height: 75, marginRight: '1rem' }}
                />
            </Grid> */}

              <Grid item xs={12} sm={12} md={6}>

                <TextField
                  required
                  label="First Name / Last Name"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="First Name Last Name"
                  variant="outlined"
                  value={name}
                  onChange={(event) => setName(event.target.value.toUpperCase())}
                  
                />

                <TextField
                  required
                  label="ID Number"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="1020304050"
                  value={identityNumber}
                  onChange={(event) => {
                    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
                    const formattedValue = inputValue.slice(0, 10); // Ensure max length of 10 digits
                    setIdentityNumber(formattedValue);
                  }}
                />
                
               
                <TextField
                  required
                  label="Date Of Birth"
                  variant="outlined"
                  value={dateOfBirth}
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="DD/MM/YYYY in Hijri"
                  onChange={(event) => {
                    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
                    let formattedValue = inputValue;

                    if (inputValue.length > 4) {
                      const day = inputValue.slice(0, 2);
                      const month = inputValue.slice(2, 4);
                      const year = inputValue.slice(4, 8);

                      // Enforce day, month, and year constraints
                      const validDay = day <= 31 ? day : "31";
                      const validMonth = month <= 12 ? month : "12";
                      const validYear = year <= 1445 ? year : "1445";

                      formattedValue = `${validDay}/${validMonth}/${validYear}`;
                    } else if (inputValue.length > 2) {
                      const day = inputValue.slice(0, 2);
                      const month = inputValue.slice(2, 4);

                      // Enforce day and month constraints
                      const validDay = day <= 31 ? day : "31";
                      const validMonth = month <= 12 ? month : "12";

                      formattedValue = `${validDay}/${validMonth}`;
                    }

                    setDateOfBirth(formattedValue);
                  }}
                />

                

                <TextField
                  required
                  label="Phone Number"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="0541000010"
                  value={phoneNumber}
                  onChange={(event) => {
                    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
                    const formattedValue = inputValue.slice(0, 10); // Clear if not starting with "05"
                    setPhoneNumber(formattedValue);
                  }}
                  inputProps={{
                    maxLength: 10, // Set the maximum length
                  }}
                />

              </Grid>
            
              <Grid item xs={12} sm={12} md={6}>
              
              <TextField
                  required
                  label="Car Manufacturer"  
                  variant="outlined"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="Toyota"
                  value={carManufacturer}
                  onChange={(event) => setCarManufacturer(event.target.value.toUpperCase())}
                />

                <TextField
                  required
                  label="Car Model"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="Camry"
                  value={carModel}
                  onChange={(event) => setCarModel(event.target.value.toUpperCase())}
                />

                <TextField
                  required
                  label="Car Production Year"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="2023"
                  value={carProductionYear}
                  onChange={(event) => {
                    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
                    const formattedValue = inputValue.slice(0, 4); // Limit to 4 digits
                    setCarProductionYear(formattedValue);
                  }}
                  inputProps={{
                    maxLength: 4, // Set the maximum length
                  }}
                />

                <TextField
                  required
                  label="Car Color"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="RED"
                  value={carColor}
                  onChange={(event) => setCarColor(event.target.value.toUpperCase())}
                />

              </Grid>

              <Grid item xs={12} sm={12} md={6}>
               
              {/* <TextField
                  required
                  label="Driver License Number"
                  variant="outlined"
                  value={driverLicense}
                  onChange={(event) => setDriverLicense(event.target.value)}
                /> */}

                

                <TextField
                  required
                  label="Plate Letters"
                  variant="outlined"
                  value={plateLetters}
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="ABC"
                  onChange={(event) => {
                    const inputValue = event.target.value.replace(/[^a-zA-Z]/g, ""); // Remove non-letter characters
                    const formattedValue = inputValue.slice(0, 3); // Limit to 3 letters
                    setPlateLetters(formattedValue.toUpperCase()); // Convert to uppercase
                  }}
                  inputProps={{
                    maxLength: 3, // Set the maximum length
                  }}
                />

                <TextField
                  required
                  label="Plate Number"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="123"
                  value={plateNumber}
                  onChange={(event) => {
                    const inputValue = event.target.value.replace(/[^0-9]/g, ""); // Remove non-digit characters
                    const formattedValue = inputValue.slice(0, 4); // Limit to 4 digits
                    setPlateNumber(formattedValue); // Set the formatted value
                  }}
                  inputProps={{
                    maxLength: 4, // Set the maximum length
                  }}
                />

                <TextField
                  required
                  label="Vehicle Sequence Number"  
                  variant="outlined"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="1020304050"
                  value={vehicleSequenceNumber}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
                    setVehicleSequenceNumber(numericValue);
                  }}
                />


              </Grid>


            

              <Grid item xs={12} sm={12} md={6}>
                              
                {/* <TextField
                  required
                  label="Car Type"
                  variant="outlined"
                  value={carType}
                  onChange={(event) => setCarType(event.target.value.toUpperCase())}
                /> */}
                
                <FormControl variant="outlined" required sx={{width:{xs:'75%',md:'39%'}, mt:{xs:0,md:1}, mr:{xs:0,md:1}, ml: {xs:1,md:1}, mb: {xs:1, md:0}}}>
                  <InputLabel>Car Type</InputLabel>
                  <Select
                    label="Car Type"
                    value={carType}
                    onChange={(event) => setCarType(event.target.value)}
                    sx={{ backgroundColor: 'white', borderRadius: '4px' }}
                  >
                    <MenuItem value={1}>Private Car</MenuItem>
                    <MenuItem value={2}>Taxi</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  required
                  label="Number of Passengers"  
                  variant="outlined"
                  value={numberOfPassenegers}
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="4"
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
                    setNumberOfPassenegers(numericValue);
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} mt={10}>
                <FileUpload id="Dricing License" label="Driving License" hint="From Tawakkalna App" accept="image/*," onChange={handleDrivingLicenceUpload} selectedFile={drivingLicenseFile} />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <FileUpload id="National/Iqama ID" label="National/Iqama ID" hint="From Tawakkalna App" accept="image/*," onChange={handlePersonalIdUpload} selectedFile={personalIdFile}/>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FileUpload id="Vehicle License" label="Vehicle License" hint="From Tawakkalna App" accept="image/*," onChange={handleVehichleLicenseUpload} selectedFile={vehichleLicenseFile} />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FileUpload id="Vehicle Insurance" label="Vehicle Insurance" hint="" accept="image/*, PDF" onChange={handleVehichleInsuranceUpload} selectedFile={vehichleInsuranceFile}/>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FileUpload id="Vehicle Photo" label="Vehicle Photo" hint="" accept="image/*" onChange={handleVehiclePhotoUpload} selectedFile={vehiclePhoto}/>
              </Grid>


             
             
              <Grid item xs={12} sm={12} md={12} alignItems="center" justifyContent="center" align="center">
                <Button type="submit" variant="outlined" sx={{color:'#000', borderColor:'#000'}} >
                {loading ? 'Submitting...' : 'Register'}

                </Button>
              </Grid>

            </Grid>
          </Box>

        </Grid>

        
      </Grid>
    </StyledSection>
  );
};

const Home = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />

      <Container>
        <RegistrationForm />
      </Container>

      <Footer />
    </ThemeProvider>
  );
};

export default Home;
